import React, { useState } from "react"
import Modal from "@components/Modal"
import Button from "@components/inputs/Button"
import { disablePaste } from "@features/keys/api/ManagePasteKeys"
import toast from "react-hot-toast"
import GetKeyEnabledPaste from "@features/keys/api/models/responses/GetKeyEnabledPaste"
import { useAtom } from "jotai"
import { getToken } from "@features/users/account/Account.atom"
import { keyDeletionId, keyDeletionVisible, KeyEnabledPastes } from "@features/keys/Keys.atom"

export default function ConfirmPasteKeyDeletion() {
    const [ses] = useAtom(getToken)
    const [data, setData] = useAtom(KeyEnabledPastes)
    
    const [visible, setVisible] = useAtom(keyDeletionVisible)
    const [id, setId] = useAtom(keyDeletionId)
    
    const [confirmLoading, setConfirmLoading] = useState(false)
    
    const confirmDeletion = () => {
        disablePaste(ses, id)
            .then(() => {
                toast.success("Successfully disabled keys for that paste!")

                let keys = Object.keys(data)

                for (let i = 0; keys.length > i; i++) {
                    const keyIndex = i + 1 // pages start at 1

                    let pasteKeys = data[keyIndex] as GetKeyEnabledPaste[]

                    let includes = pasteKeys.filter(
                        (pasteKey) => pasteKey.paste.pasteID !== id
                    )

                    if (pasteKeys.length !== includes.length) {
                        setData((prev: any) => ({
                            ...prev,
                            [keyIndex]: [...includes],
                        }))
                        break
                    }
                }
            })
            .catch((message) => toast.error(message))
            .finally(() => {
                setConfirmLoading(false)
                setVisible(false)
                toast.success("Key has been successfully deleted!")
            })
    }

    const cancelDeletion = () => {
        setVisible(false)
        setConfirmLoading(false)
        setId("")
    }

    return <Modal
        title={"Confirm Deletion"}
        visible={visible}
        setVisible={setVisible}
        sizing={"xsmall"}
    >
        <p>Are you sure you want to delete this paste key?</p>

        <div className="flex flex-row gap-4 items-center justify-center mt-5">
            <Button buttonStyle={"danger"} onClick={confirmDeletion} loading={confirmLoading}>Yes</Button>
            <Button buttonStyle={"secondary"} onClick={cancelDeletion}>No</Button>
        </div>
    </Modal>
}