import React, { useEffect, useState } from "react"
import { capitalize } from "@util/Util"
import Dash from "@features/dashboard/components/Dashboard"
import Referrals from "../users/referrals/components/Referrals"
import KeyDashboardTab from "@features/keys/KeyDashboardTab"
import Settings from "@features/dashboard/components/settings/Settings";
import Profile from "@features/dashboard/components/profile/Profile";
import { useSearchParams } from "react-router-dom"

const tabOptions: any = {
    dashboard: <Dash />,
    profile: <Profile />,
    settings: <Settings />,
    referrals: <Referrals />,
    key_API: <KeyDashboardTab />,
}

const fixStr = (str: string) => {
    let strings: string[]

    if (str.includes("_")) {
        strings = str.split("_")
    } else strings = [str]

    strings = strings.map(str => str[0].toUpperCase() + str.substring(1, str.length))

    return strings.join(" ")
}

const Dashboard = () => {
    const [tab, setTab] = useState("dashboard")
    const [search, setSearch] = useSearchParams()

    useEffect(() => {
        setSearch(prev => {
            prev.set("", tab.toLowerCase())
            return prev
        })
    }, [tab])

    useEffect(() => {
        const searchParam = search.get("")?.toLowerCase()

        if (searchParam && searchParam !== tab && Object.keys(tabOptions).includes(searchParam)) {

            setTab(search.get("") ?? "dashboard")
        }
    }, [search])

    return (
        <div className="w-full self-start">
            <div className="flex-wrap flex bg-secondaryBackground dark:bg-dark_secondaryBackground px-4 py-2 rounded-t-lg flex-row gap-2 border-b-2 border-greyText dark:border-dark_greyText">
                {Object.keys(tabOptions).map((key) => (
                    <button
                        className={`transition-all flex flex-row gap-2 text-lg poppins p-2 ${
                            key !== tab
                                ? "text-greyText dark:text-dark_greyText"
                                : "text"
                        }`}
                        onClick={() => setTab(key)}
                        key={key}
                    >
                        {fixStr(key)}
                    </button>
                ))}
            </div>

            <div className="p-4 island">{tabOptions[tab]}</div>
        </div>
    )
}

export default Dashboard
