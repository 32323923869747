import { Helmet } from "react-helmet"
import { useAtom } from "jotai"
import { SeoDescription, SeoTitle } from "@features/layout/Layout.atom"

/**
 * Allows meta tags to be changed on a per-page basis.
 */
const Seo = () => {
    const [title] = useAtom(SeoTitle)

    return (
        <Helmet>
            <meta property="og:site_name" content={title} />
            <meta name="twitter:title" content={title} />
            <title>{title}</title>
        </Helmet>
    )
}

export default Seo