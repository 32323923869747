import React, { useEffect, useState } from "react"
import Button from "@components/inputs/Button"
import { useNavigate, useParams } from "react-router-dom"
import { finalizeProcess, startProcess } from "@features/keys/api/PasteKeys"
import toast from "react-hot-toast"
import { Helmet } from "react-helmet"
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile"
import { INVIS_CLOUDFLARE_SITE_KEY, PRODUCTION } from "@util/Util"
import useTurnstile from "@components/hooks/UseTurnstile"

/**
 * The process of retrieving a key for a paste.
 */
const Interstitial = () => {
    let { token: id } = useParams() // id of the paste
    const nav = useNavigate()

    const [token, setToken] = useState<string>()
    const [customAdCode, setCustomAdCode] = useState<string>()
    const [monetized, setMonetized] = useState<boolean>()

    // manages the time remaining til the user can advance
    const [secondsRemaining, setSecondsRemaining] = useState(5)
    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsRemaining((current) => current - 1)
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        // begin the process & get initial token
        startProcess(id as string)
            .then((response) => {
                setToken(response.token)
                setMonetized(response.monetized)
                setCustomAdCode(response.customAdCode)
            })
            .catch(() => {
                // send the user back to the homepage if their paste ID is invalid
                toast.error("That paste is invalid!")
                nav("/")

                return undefined
            })
    }, [id, nav])

    // retrieve the final token (aka key) and
    // send the user to view it
    const retrieveFinalToken = () => {
        finalizeProcess(token ?? "", ref.current?.getResponse() ?? "")
            .catch(() =>
                toast.error(
                    "There was an issue getting your key, please try again."
                )
            )
            .then((key) => nav("/key/" + key))
            .finally(() => ref.current?.reset())
    }

    const [turnstile, ref] = useTurnstile("interstitial")

    return (
        <div className="mt-6 flex h-full w-full flex-col items-center justify-center gap-8">
            {monetized && (
                <Helmet>
                    <script type="text/javascript" src="//acscdn.com/script/aclib.js" id="aclib" />
                    <script>
                        {'var fFlag = false;\n' +
                            '                            var nab_css = `\n' +
                            '                            .nab_wrapper {\n' +
                            '                                position: fixed;\n' +
                            '                                top: 0;\n' +
                            '                                left: 0;\n' +
                            '                                background: rgba(0, 0, 0, 0.935);\n' +
                            '                                width: 100%;\n' +
                            '                                height: 100%;\n' +
                            '                                display: none; /* Initially hidden */\n' +
                            '                                align-items: center;\n' +
                            '                                justify-content: center;\n' +
                            '                                z-index: 9999;\n' +
                            '                            }\n' +
                            '                            .nab_card {\n' +
                            '                                max-width: 420px;\n' +
                            '                                width: 100%;\n' +
                            '                                padding: 20px;\n' +
                            '                                box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;\n' +
                            '                                border-radius: 10px;\n' +
                            '                                font-family: \'Arial\', sans-serif;\n' +
                            '                                color: black;\n' +
                            '                                text-align: center;\n' +
                            '                                color: white;\n' +
                            '                                background: #222222;\n' +
                            '                            }\n' +
                            '                            .nab_title {\n' +
                            '                                font-size: 20px;\n' +
                            '                                font-weight: bold;\n' +
                            '                                margin-bottom: 15px;\n' +
                            '                                color: #FF4800;\n' +
                            '                            }\n' +
                            '                            .nab_desc {\n' +
                            '                                font-size: 16px;\n' +
                            '                                line-height: 24px;\n' +
                            '                            }\n' +
                            '                            `;\n' +
                            '                            \n' +
                            '                            var nab_html = `\n' +
                            '                            <div class="nab_wrapper">\n' +
                            '                                <div class="nab_card">\n' +
                            '                                    <span class="nab_title">⚠️ Please Disable Your Adblock</span>\n' +
                            '                                    <br/>\n' +
                            '                                    <span class="nab_desc">Please disable your ad blocker to access this website. \n' +
                            '                                    If you see this message again, consider using a different browser.</span>\n' +
                            '                                </div>\n' +
                            '                            </div>\n' +
                            '                            `;\n' +
                            '\n' +
                            '                            function setupAntiAdblock() {\n' +
                            '                                var styleTag = document.createElement(\'style\');\n' +
                            '                                styleTag.textContent = nab_css;\n' +
                            '                                document.head.appendChild(styleTag);\n' +
                            '\n' +
                            '                                var div = document.createElement(\'div\');\n' +
                            '                                div.innerHTML = nab_html;\n' +
                            '                                document.body.appendChild(div);\n' +
                            '                            }\n' +
                            '\n' +
                            '                            function displayMessage() {\n' +
                            '                                if (!fFlag) {\n' +
                            '                                    fFlag = true;\n' +
                            '                                    document.querySelector(\'.nab_wrapper\').style.display = \'flex\';\n' +
                            '                                }\n' +
                            '                            }\n' +
                            '\n' +
                            '                            function checkAdblocker() {\n' +
                            '                                fetch(\'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js\', {redirect: \'manual\'})\n' +
                            '                                    .then(function(response) {\n' +
                            '                                        if (response.type === \'opaqueredirect\' || !response.ok) {\n' +
                            '                                            displayMessage();\n' +
                            '                                        }\n' +
                            '                                    })\n' +
                            '                                    .catch(displayMessage);\n' +
                            '\n' +
                            '                                var testEl = document.createElement(\'div\');\n' +
                            '                                testEl.className = \'ad\';\n' +
                            '                                document.body.appendChild(testEl);\n' +
                            '                                setTimeout(function() {\n' +
                            '                                    var testElStyle = window.getComputedStyle(testEl);\n' +
                            '                                    if (testElStyle.display === \'none\' || testElStyle.visibility === \'hidden\') {\n' +
                            '                                        displayMessage();\n' +
                            '                                    }\n' +
                            '                                    document.body.removeChild(testEl);\n' +
                            '                                }, 1500);\n' +
                            '                            }\n' +
                            '\n' +
                            '                            function initAntiAdblock() {\n' +
                            '                                setupAntiAdblock();\n' +
                            '                                checkAdblocker();\n' +
                            '                            }\n' +
                            '                            \n' +
                            '                            setTimeout(() => { initAntiAdblock(); }, 1000);'}
                    </script>
                    <script type="text/javascript">
                        {'aclib.runPop({ zoneId: "8496362" });'}
                    </script>
                </Helmet>
            )}

            {turnstile}

            <div className="flex w-full flex-col items-center justify-center gap-8">
                <Button
                    buttonStyle="success"
                    disabled={secondsRemaining > 0}
                    onClick={retrieveFinalToken}
                >
                    Continue to Key{" "}
                    {secondsRemaining > 0
                        ? `in ${secondsRemaining} seconds`
                        : ""}
                </Button>
            </div>
        </div>
    )
}

export default Interstitial
