import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Paste} from "@features/pastes/api/models/Paste";
import {getKeyDetails} from "@features/keys/api/PasteKeys";
import toast from "react-hot-toast";
import Spinner from "@components/Spinner";
import Interstitial from "@views/pastes/Interstitial"

export const KeyLoader = () => {
    const { token } = useParams()

    if (token?.includes("-"))
        return <Key/>
    else
        return <Interstitial/>
}

const Key = () => {
    const { token } = useParams()
    const nav = useNavigate()
    const [{ paste, expires }, setKeyDetails] = useState<{ paste?: Paste, expires?: number }>({ })

    useEffect(() => {
        getKeyDetails(token ?? "")
            .then(res => setKeyDetails(res))
            .catch(err => {
                toast.error(err)
                nav("/")
            })
    }, [token])

    if (!paste || !expires) {
        return <div className="flex flex-row justify-center items-center">
            <Spinner/>
        </div>
    }

    return <div className="ml-48 mr-48 flex items-center justify-center flex-col island p-6 rounded-lg mt-6">
        <h2 className="text-2xl mb-4 poppins">{paste.title === "" ? "Untitled Paste" : paste.title}</h2>
        <p>{paste.description}</p>
        <p>Expires on <span className="monospace mb-4">{new Date(expires).toLocaleString()}</span></p>

        <p className="primaryBackground mt-4 p-4 rounded-md max-w-lg break-words monospace">
            {token}
        </p>
    </div>
}

export default Key