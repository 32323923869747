import Button from "@components/inputs/Button"
import Paginator from "@components/Paginator"
import React, { useEffect, useState } from "react"
import {
    getPasteKeyAnalytics,
} from "@features/keys/api/ManagePasteKeys"
import { useAtom } from "jotai"
import { getToken } from "@features/users/account/Account.atom"
import toast from "react-hot-toast"
import {
    keyCreationProcess,
    KeyEnabledPastes
} from "@features/keys/Keys.atom"
import usePasteKeyAnalytics from "@features/keys/hooks/usePasteKeyAnalytics"
import GetKeyEnabledPaste from "@features/keys/api/models/responses/GetKeyEnabledPaste"
import KeySettings from "@features/keys/components/KeySettings"
import KeyDetailSelection from "@features/keys/components/creation/KeyDetailSelection"
import KeyPasteSelection from "@features/keys/components/creation/KeyPasteSelection"
import KeyOverview from "@features/keys/components/creation/KeyOverview"
import { PasteEntry } from "@features/keys/components/view/PasteEntry"
import ConfirmPasteKeyDeletion from "@features/keys/components/view/ConfirmPasteKeyDeletion"

/**
 * Pastes that have the key system enabled
 * @constructor
 */
const EnabledKeyPastes = () => {
    const [analytics] = usePasteKeyAnalytics(true)
    const [session] = useAtom(getToken)

    // information about the page
    const [maxPage, setMaxPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalResults, setTotalResults] = useState(0)

    const [data, setData] = useAtom(KeyEnabledPastes)

    // this is from the initial loading of paste keys in analytics
    useEffect(() => {
        const response = analytics?.pasteKeys

        // by checking the length, this prevents a duplicate load when modifying local data
        if (response && Object.keys(data).length === 0) {
            setMaxPage(response.maxPage)
            setCurrentPage(response.currentPage)
            setTotalResults(response.totalResults)
            setData((prev: any) => ({
                ...prev,
                [response.currentPage]: [...response.pageContents],
            }))
        }
    }, [analytics, setData])

    // when the paginator changes the page, load the new page
    useEffect(() => {
        // loads a page by it's page number
        const loadPage = (page: number) => {
            getPasteKeyAnalytics(session, page)
                .then(({ pasteKeys: response }) => {
                    setMaxPage(response.maxPage)
                    setCurrentPage(response.currentPage)
                    setTotalResults(response.totalResults)
                    setData((prev: any) => ({
                        ...prev,
                        [response.currentPage]: [...response.pageContents],
                    }))
                })
                .catch((error) => toast.error(error))
                .finally(() =>
                    console.log(`Finished loading ${page} page of Paste Keys!`)
                )
        }

        if (!data[currentPage] && analytics && currentPage !== 1) {
            loadPage(currentPage)
        }
    }, [analytics, currentPage, data, session, setData])

    const [, setStep] = useAtom(keyCreationProcess)

    return (
        <div className="lg:min-w-[50%] lg:max-w-[50%] w-full flex flex-col md:w-auto items-center justify-center primaryBackground p-4 rounded-lg md:mb-0 mb-4">
            <h3 className="poppins text-lg mb-4">Enabled Pastes</h3>

            <KeySettings />

            <KeyDetailSelection />
            <KeyPasteSelection />
            <KeyOverview />

            <ConfirmPasteKeyDeletion/>

            {Object.hasOwn(data, currentPage) &&
            data[currentPage].length > 0 ? (
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                        <tr>
                            <th scope="col" className="md:block lg:block hidden w-36 py-3 px-6">
                                Name
                            </th>

                            <th scope="col" className="w-36 py-3 px-6">
                                Paste
                            </th>

                            <th scope="col" className="py-3 px-6">
                                Manage
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {data[currentPage].map(
                            (pasteKey: GetKeyEnabledPaste, index: number) => (
                                <PasteEntry paste={pasteKey} index={index} />
                            )
                        )}
                    </tbody>
                </table>
            ) : (
                <div className="flex items-center ml-6 justify-center">
                    <p>You have no enabled pastes!</p>
                </div>
            )}

            {/* Key system controls */}
            <div className="flex md:flex-row flex-col items-center md:gap-8 gap-2 justify-between mt-6 mb-4">
                {/* Begin key creation process */}
                <Button onClick={() => setStep(1)}>
                    Enable <i className="fa-solid fa-plus"></i>
                </Button>

                <Paginator
                    disableResultsSummary={true}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    maxPage={maxPage}
                    totalResults={totalResults}
                    pageCount={maxPage}
                />
            </div>
        </div>
    )
}

export default EnabledKeyPastes
