import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile"
import React, { MutableRefObject, ReactElement } from "react"
import { INVIS_CLOUDFLARE_SITE_KEY } from "@util/Util"

/**
 * Create an invisible Turnstile object with a unique ID.
 *
 * @param id The unique ID of this Turnstile instance.
 */
export default function useTurnstile(
    id: string
): [ReactElement, MutableRefObject<TurnstileInstance | undefined>] {
    const ref = React.useRef<TurnstileInstance>()

    return [
        <Turnstile
            id={id}
            options={{
                size: "invisible",
            }}
            onError={err => {
                console.info(
                    `Turnstile instance has errored, attempting a reset: ${err}`
                )

                ref.current?.reset()
            }}
            onExpire={() => {
                console.info("Turnstile key has expired, attempting a reset")

                ref.current?.reset()
            }}
            onSuccess={() => {
                console.log("Successfully updated Cloudflare token")
            }}
            siteKey={INVIS_CLOUDFLARE_SITE_KEY}
            ref={ref}
        />,
        ref,
    ]
}
