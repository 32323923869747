import { useAtom } from "jotai"
import { SeoTitle } from "@features/layout/Layout.atom"

/**
 * Use the SEO and modify SEO description.
 */
const useSeo = () => {
    const [, setTitle] = useAtom(SeoTitle)

    return [(title: string) => setTitle(title)]
}

export default useSeo