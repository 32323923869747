import React, { useEffect, useState } from "react"
import { useAtom } from "jotai"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { GetPasteResponse } from "@features/pastes/api/models/GetPasteResponse"
import {
    getPaste,
    updatePasteAttribute,
    updatePasteThumbnail,
} from "@features/pastes/api/Pastes"
import Spinner from "../../components/Spinner"
import { Visibility as Vis } from "@features/pastes/api/Visibility"
import PasteEditor from "@features/pastes/components/editor/PasteEditor"
import { account, getToken } from "@features/users/account/Account.atom"
import { statusReport } from "@features/status/Status.atom"
import { undefIfBlank } from "@util/Util"

/**
 *
 * @returns The page to edit pastes
 */
const Edit = () => {
    let { id } = useParams()

    const [status] = useAtom(statusReport)

    const [sessionToken] = useAtom(getToken)
    const [acc] = useAtom(account)
    const nav = useNavigate()

    // when the paste is loaded
    const [paste, setPaste] = useState<GetPasteResponse>()

    // if the paste is invalid
    const [invalidPaste, setInvalidPaste] = useState(false)

    const [loading, setLoading] = useState(false)

    // update the paste based on what changed
    const updatePaste = async (
        ev: React.FormEvent<HTMLFormElement>,
        editorValue: string,
        tags: string[],
        gameLink: string,
        universalScript: boolean,
        thumbnail?: File
    ) => {
        ev.preventDefault()
        setLoading(true)

        const data = new FormData(ev.currentTarget)

        let title = data.get("title") as string,
            desc = data.get("description") as string,
            youtubeLink = data.get("youtubeLink") as string,
            visibility = data.get("visibility") as string,
            monetized = data.get("paste-monetized") as string

        monetized = `${monetized === "on"}`

        const pasteID = paste?.paste.pasteID

        // return if the paste is undefined (shouldn't happen)
        if (!pasteID || !paste) {
            toast.error("Invalid paste!")
            setLoading(false)
            return
        }

        let promises = []

        /**
         * Update an attribute.
         *
         * Shorthand to add {@link updatePasteAttribute} to {@link promises}.
         */
        const updateAttribute = (attribute: string, value?: string) =>
            promises.push(
                updatePasteAttribute(sessionToken, pasteID, attribute, value)
            )

        // if the title exists & is different send a request to update
        if (undefIfBlank(title) && title !== paste.paste.title)
            updateAttribute("title", title)

        // if the desc exists & is different send a request to update
        if (undefIfBlank(desc) && desc !== paste?.paste.description)
            updateAttribute("description", desc)

        // if universal script has been updated, send an updaet
        // this must be done before game link just incase that makes a change too
        if (universalScript !== paste?.paste.universalScript)
            updateAttribute("universalScript", `${universalScript}`)

        // if game link has been updated, send an update
        if (undefIfBlank(gameLink) !== paste?.paste.gameLink)
            updateAttribute("gameLink", gameLink)

        // if youtube link has been updated, send an update
        if (undefIfBlank(youtubeLink) !== paste?.paste.youtubeLink)
            updateAttribute("youtubeLink", youtubeLink)

        // if the content of the paste is different, send an update to update
        // must be longer than or equal to 1 character
        if (
            editorValue &&
            editorValue.length !== 0 &&
            editorValue !== paste.content
        )
            updateAttribute("content", editorValue)

        if (paste.paste.monetized !== Boolean(JSON.parse(monetized)))
            updateAttribute("monetized", monetized)

        // if the visibility has changed, update
        if (
            visibility.toLowerCase() !==
            Vis[paste.paste.visibility].toLowerCase()
        )
            updateAttribute("visibility", visibility)

        // if the tags have changed, update
        if (JSON.stringify(paste.paste.tags) !== JSON.stringify(tags))
            updateAttribute("tags", JSON.stringify(tags))

        // if the user uploaded a thumbnail, update it.
        if (thumbnail)
            promises.push(
                updatePasteThumbnail(sessionToken, pasteID, thumbnail)
            )

        if (promises.length === 0) {
            toast.success("No changes were made!")
            setLoading(false)
        } else
            await toast
                .promise(Promise.all(promises), {
                    success: "Your paste has been successfully updated!",
                    loading: "Updating your paste",
                    error: (e) => e,
                })
                .finally(() => setLoading(false))
    }

    // load the paste on the site load
    useEffect(() => {
        // loads the paste data
        const loadPaste = async () => {
            const token = sessionToken === "" ? undefined : sessionToken

            try {
                const pasteData = await getPaste(id ?? "", token)

                setPaste(pasteData)
            } catch (e) {
                nav("/")
                toast.error("That paste could not be found!", {
                    id: "pasteNotFound",
                })
            }
        }

        loadPaste().then(() => console.log("Finished loading paste!"))
    }, [id, nav, sessionToken])

    // this ensures the user is the owner of the paste
    useEffect(() => {
        const userDetails = acc?.user?.userID
        const pasteDetails = paste?.paste?.userID

        if (userDetails && pasteDetails && pasteDetails !== userDetails) {
            setInvalidPaste(true)
        }
    }, [acc, paste])

    // if the paste is invalid
    if (invalidPaste) {
        nav("/")
        toast.error("That paste could not be found!", { id: "pasteNotFound" })
        return <></>
    }

    // while the paste is loading
    if (!paste || !acc) {
        return (
            <p className="text-center">
                <Spinner />
            </p>
        )
    }

    return (
        <section className="mt-5 flex w-full flex-col items-center justify-center gap-4">
            <div className="island flex w-3/4 flex-col gap-4 rounded-lg p-2">
                <PasteEditor
                    initPasteResponse={paste}
                    usePresetFromBackend={false}
                    loading={loading}
                    onSubmit={updatePaste}
                    viewButton={true}
                    onView={() => nav(`/${paste?.paste?.pasteID}`)}
                />
            </div>
        </section>
    )
}

export default Edit
