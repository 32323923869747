import { useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import { FormEvent } from "react"
import toast from "react-hot-toast"
import { LoginResponse } from "../api/responses/LoginResponse"
import Button from "@components/inputs/Button"
import Input from "@components/inputs/Input"
import Modal from "@components/Modal"
import {
    twoFactorContinue,
    twoFactorVisibility,
} from "@features/users/login/Login.atom"
import {account, setToken} from "@features/users/account/Account.atom"
import { mfaLogin } from "@features/users/login/api/Mfa"
import { AccountStore } from "@features/users/account/api/models/AccountStore"

/**
 * When two factor is used.
 */
const TwoFactor: React.FC = () => {
    const [visible, setVisible] = useAtom(twoFactorVisibility)
    const [loading, setLoading] = useState(false)

    // the data continued
    const [mfaContinue] = useAtom(twoFactorContinue)

    // used on successful login
    const [, setAccStore] = useAtom(account)
    const [, setSesToken] = useAtom(setToken)

    const onSubmit = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault()
        const formData = new FormData(ev.currentTarget)
        setLoading(true)

        let code = formData.get("code")?.valueOf() as string

        // this shouldn't really ever happen
        if (!code || !mfaContinue) {
            setLoading(false)
            toast.error(
                "There was an issue with your two factor authentication. Please reload and try again."
            )
            return
        }

        try {
            const request = await mfaLogin(
                mfaContinue.userID,
                code,
                mfaContinue.mfaVerification
            )

            // same as regular login
            toast.success("Logging in...")

            const { token, user } = request as LoginResponse

            setSesToken(token)
            setAccStore({
                username: user.user.username,
                userId: user.user.userID,
                email: user.email,
                accountCreation: user.user.registrationDate,
                mfaEnabled: user.mfaEnabled,
            } as AccountStore)

            setVisible(false)
        } catch (e) {
            toast.error(`${e}`)
        }

        setLoading(false)
    }

    return (
        <Modal title="Two-Factor" visible={visible} setVisible={setVisible}>
            <form className="space-y-6" onSubmit={onSubmit}>
                <div>
                    <Input
                        type="text"
                        name="code"
                        placeholder="2FA code here"
                        maxLength={6}
                        required
                        autoFocus={true}
                    />
                </div>
                <div className="flex items-center justify-evenly">
                    <Button type="submit" className="w-auto" loading={loading}>
                        Submit
                    </Button>
                    <Button
                        buttonStyle="secondary"
                        onClick={() => setVisible(false)}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

export default TwoFactor
