import Input from "@components/inputs/Input"
import React from "react"
import { Asterisk } from "@features/pastes/components/editor/Asterisk"

/**
 * Properties for {@link PasteGameLink}.
 *
 * @param universalScript If this script is universal, and thus doesn't require a {@link gameLink}.
 * @param gameLink If {@link universalScript} is true, then a Roblox game link is required.
 * @param setGameLink If {@link gameLink} is modified within {@link PasteGameLink}.
 */
type GameLinkProps = {
    universalScript: boolean
    gameLink: string
    setGameLink: (newGameLink: string) => void
}

/**
 * The modification of a paste's game link within {@link PasteEditor}.
 *
 * If the script is "universal," then it doesn't require a Roblox game link.
 * However, if it's not universal, then a game link is expected to be added.
 *
 * @see PasteEditor
 */
export default function PasteGameLink({
    universalScript,
    gameLink,
    setGameLink,
}: GameLinkProps) {
    return (
        <>
            <label className="input-label w-full md:ml-0">
                Game Link {!universalScript && <Asterisk/>}
                <br />

                <Input
                    name="gameLink"
                    placeholder="Game link here"
                    value={gameLink}
                    onChange={(ev) => setGameLink(ev.currentTarget.value)}
                    maxLength={128}
                />
            </label>
        </>
    )
}
