import { useAtom } from "jotai"
import toast from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import FeedbackModal, {
    feedbackModalVisibility,
} from "../../feedback/components/FeedbackModal"
import { loginModalVisibility } from "@features/users/login/Login.atom"
import { isLoggedIn } from "@features/users/account/Account.atom"

/**
 * Footer that's seen on the end of all pages.
 */
const Footer = () => {
    const [, setLoginModal] = useAtom(loginModalVisibility)
    const [, setFeedbackModal] = useAtom(feedbackModalVisibility)

    const [loggedIn] = useAtom(isLoggedIn)
    const nav = useNavigate()

    const loginButton = () => {
        if (loggedIn) nav("/account")
        else {
            setLoginModal(true)
        }
    }

    const openFeedback = () => {
        if (loggedIn) {
            setFeedbackModal(true)
        } else {
            toast.error("You must be logged in for this!")
        }
    }

    return (
        <footer className="primaryBackground w-full p-4 sm:p-6 lg:px-28 xl:px-36 2xl:px-44">
            <FeedbackModal />

            <hr className="my-6 border-gray-200 dark:border-stone-800 sm:mx-auto lg:my-8" />

            <div className="md:flex md:justify-between">
                <div className="mb-6 md:mb-0 divide-x-2 gap-8 flex flex-row items-center justify-center ">
                    <a href="/" className="flex items-center">
                        <span className="titleFont primary self-center whitespace-nowrap text-2xl font-medium">
                            <i className="fa-solid fa-scroll"></i> BloxyBin
                        </span>
                    </a>

                    <Link
                        to={"https://discord.gg/WP2fbawp5V"}
                        target={"_blank"}
                        className="cursor-pointer hover:underline pl-8"
                    >
                        <img
                            width={128}
                            src="https://www.elnn.eu/wp-content/uploads/2023/05/Discord-click-to-join-our-Discord-Community.png"
                            alt="Join our Discord"
                        />
                    </Link>
                </div>
                <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 sm:gap-6">
                    <div>
                        <h2 className="mb-6 text-sm font-semibold uppercase text-gray-900 dark:text-white">
                            Account
                        </h2>
                        <ul className="text-gray-600 dark:text-gray-400">
                            <li className="mb-4">
                                <a
                                    onClick={() => loginButton()}
                                    className="hover:underline"
                                >
                                    Login
                                </a>
                            </li>
                            <li>
                                <a href="/register" className="hover:underline">
                                    Register
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2
                            className="mb-6 cursor-pointer text-sm font-semibold uppercase text-gray-900 hover:underline dark:text-white"
                            onClick={() => nav("/legal")}
                        >
                            Legal
                        </h2>
                        <ul className="text-gray-600 dark:text-gray-400">
                            <li className="mb-4">
                                <button
                                    onClick={() => nav("/legal/privacy")}
                                    className="hover:underline"
                                >
                                    Privacy Policy
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => nav("/legal/tos")}
                                    className="hover:underline"
                                >
                                    Terms &amp; Conditions
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="mb-6 text-sm font-semibold uppercase text-gray-900 dark:text-white">
                            Other
                        </h2>
                        <ul className="text-gray-600 dark:text-gray-400">
                            <li className="mb-4">
                                <a
                                    onClick={() => openFeedback()}
                                    className="cursor-pointer hover:underline"
                                >
                                    Feedback
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Onion Routing + Copyright */}
            <div className="pt-8 text-center">
                <span className="text-sm text-gray-500 dark:text-gray-400">
                    <span className="sm:text-xs break-all">
                        bloxybinefarao6ig5g4lrbut2ma2th5tcs7u2kjgkby7isussurjqyd.onion
                    </span>
                    <br />© {new Date().getFullYear()}{" "}
                    <a href="/" className="hover:underline">
                        BloxyBin
                    </a>
                    . All Rights Reserved. <br />
                    Version {process.env.REACT_APP_VERSION}
                </span>
            </div>
        </footer>
    )
}

export default Footer
