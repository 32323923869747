import React, {ReactElement, ReactNode, ReactText} from "react"

/**
 * Properties for a card.
 *
 * @remarks
 * This is utilized in {@link TimeRangedCard}, and is the main card for BloxyBin.
 * All existing HTML DIV properties exist on the card properties.
 *
 * @param title The title to be included in the card.
 * @param content The larger content of the card.
 * @param loading When true, display {@param content} as a pulsing skeleton.
 * @param viewMore When included, this enables a `View more` button, which invokes the included function when pressed.
 * @param viewMoreText Changes the button text that invokes {@param viewMore}.
 * @param action The JSX element that's included on the far right of the card.
 * @param contentClassName The class name for {@param content}. This doesn't replace, but adds onto existing classes.
 *
 * @see React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
 */
export type CardProps = {
    title: string
    children?: React.ReactNode;
    loading?: boolean
    viewMore?: () => void
    viewMoreText?: string
    action?: React.ReactNode
    contentClassName?: string
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>

/**
 * The card component for BloxyBin.
 *
 * @see CardProps
 */
export default function Card(props: CardProps) {
    return (
        <div
            {...props}
            className={`${props.className} w-full flex flex-row ${
                props.action ? "items-center" : "items-left"
            } justify-between primaryBackground p-4 gap-4 rounded-lg`}
        >
            <div>
                <div className="flex flex-row gap-2">
                    <h1 className="text-dark_greyText text-left text-md font-semibold poppins">
                        {props.title}
                    </h1>

                    {props.viewMore && (
                        <button
                            onClick={props.viewMore}
                            className="text-gray-600 text-sm underline"
                        >
                            {props.viewMoreText
                                ? props.viewMoreText
                                : "View more"}
                        </button>
                    )}
                </div>

                <p
                    className={`text-2xl mt-2 text-white ${props.contentClassName}`}
                >
                    {props.loading ? (
                        <div className="h-[32px] flex items-center animate-pulse">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-3/4"></div>
                        </div>
                    ) : (
                        props.children
                    )}
                </p>
            </div>

            {props.action && <>{props.action}</>}
        </div>
    )
}