import AceEditor from "react-ace"

import "ace-builds/src-noconflict/theme-chaos"
import "ace-builds/src-noconflict/theme-tomorrow"
import "ace-builds/src-noconflict/ext-language_tools"

import { useAtom } from "jotai"
import React from "react"
import { getTheme } from "@features/theme/Theme.atom"

/**
 * the Ace Editor with an automatic theme.
 */
const Editor: React.FC<{
    onChange?: (newStr: string) => void
    value?: string
    readOnly?: boolean
}> = ({ onChange, value, readOnly }) => {
    const [theme] = useAtom(getTheme)

    return (
        <AceEditor
            mode="text"
            value={value}
            readOnly={readOnly}
            width="100%"
            onChange={onChange}
            theme={theme === "dark" ? "chaos" : "tomorrow"}
            style={{ fontSize: "16px" }}
            showPrintMargin={false}
            setOptions={{ useWorker: false}}
        />
    )
}

export default Editor
