const Privacy = () => {
    return (
        <div className="flex justify-center items-center">
            <div className="flex flex-col max-w-2xl primaryBackground p-4 items-center rounded-lg">
                <h1 className="text-6xl poppins font-bold mb-4">
                    Privacy Policy
                </h1>
                <div>
                    <p>

                        We sadly do retain some data, but not much. An example would be your PayPal email address for
                        payments. However BloxyBin makes a strong effort to minimize data collection & does not collect
                        any
                        information not strictly necessary for its operation.
                    </p>
                    <br/>
                    <p>
                        IP addresses are never associated with a user account, however they are collected to prevent
                        view
                        botting to ensure fair payouts for everyone. These verified view logs are automatically deleted
                        after 30 days. Views on your own pastes will not log your IP address, also not every paste view,
                        is
                        a verified view for payment purposes. Verified views are a backend metric to prevent bots from
                        taking all the ad revenue.
                    </p>
                    <br/>
                    <p>Upon account deletion, all your data is wiped from our servers automatically after 30 days.</p>
                    <br/>
                    <p>
                        But don't take our word for it, we encourage everyone to request payouts in Monero, use a
                        privacy
                        hardened browser & a trusted VPN such as Mullvad or IVPN, ideally on an open-source router.
                        BloxyBin
                        will never restrict anyone accessing our site with a VPN or Tor.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Privacy
