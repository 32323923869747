import { useState, useEffect } from "react"
import toast from "react-hot-toast"
import { searchCachedPastes, searchPastes } from "@features/pastes/api/Pastes"
import PasteComponent from "@features/pastes/components/Paste"
import { Paste } from "@features/pastes/api/models/Paste"

/**
 * The popular pastes seen on the homepage.
 */
const RecentPastes = () => {
    // the loaded pastes
    const [pastes, setPastes] = useState<Paste[]>()

    // shows an error message if there was an issue loading the pastes.
    const [error, setError] = useState(false)

    // loads the recent pastes on page load.
    useEffect(() => {
        const loadPastes = async () => {
            try {
                const request = await searchCachedPastes("recent")

                setPastes(request)
            } catch (e) {
                setError(true)
                toast.error(
                    "There was an issue loading the most recent pastes."
                )
            }
        }

        loadPastes().then(() =>
            console.log("Completed loading pastes for recent pastes!")
        )
    }, [])

    // before the pastes are loaded
    if (!pastes) {
        return (
            <section className="lg:w-1/4 w-full p-2 rounded-2xl island flex flex-col justify-between">
                <div className="flex flex-col items-center justify-center w-full h-full">
                    <h1 className="text-3xl plusjakartasans font-bold text-center py-4">
                        Recent Pastes
                    </h1>
                </div>

                {/* Skeleton loading */}
                <div
                    className={`md:flex w-full justify-center items-center flex-wrap gap-3`}
                >
                    {Array(7).fill(0).map((_, index) => {
                        return (
                            <div
                                key={index}
                                role="status"
                                className={`!h-[78px] paste secondaryBackground animate-pulse min-w-full flex flex-col items-left justify-center`}
                            >
                                <div className="h-2.5 w-48 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"></div>
                                <div className="w-36 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                <span className="sr-only">Loading...</span>
                            </div>
                        )
                    })}
                </div>
            </section>
        )
    }

    // if there was an issue loading the pastes
    if (error) {
        return (
            <p className="text-center lg:w-1/4 w-full">
                <i className="fa fa-error" />
            </p>
        )
    }

    return (
        <section className="lg:w-1/4 w-full p-2 rounded-2xl island flex flex-col justify-between">
            <div className="flex flex-col items-center justify-center w-full h-full">
                <h1 className="text-3xl plusjakartasans font-bold text-center py-4">
                    Recent Pastes
                </h1>
            </div>

            <div
                className={`md:flex w-full justify-center items-center flex-wrap gap-3`}
            >
                {pastes.slice(0, 7).map((paste) => {
                    return (
                        <PasteComponent
                            key={paste.pasteID}
                            size="w-full"
                            paste={paste}
                        />
                    )
                })}
            </div>
        </section>
    )
}

export default RecentPastes;
