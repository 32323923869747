import GetKeyEnabledPaste from "@features/keys/api/models/responses/GetKeyEnabledPaste"
import { useAtom } from "jotai"
import { getToken } from "@features/users/account/Account.atom"
import { keyDeletionId, keyDeletionVisible, KeyEnabledPastes, keySettings } from "@features/keys/Keys.atom"
import React, { useState } from "react"
import { disablePaste } from "@features/keys/api/ManagePasteKeys"
import toast from "react-hot-toast"
import { Link } from "react-router-dom"
import Spinner from "@components/Spinner"
import Modal from "@components/Modal"
import Button from "@components/inputs/Button"

/**
 * A specific paste that has the key system enabled.
 */
export const PasteEntry: React.FC<{
    paste: GetKeyEnabledPaste
    index: number
}> = ({ paste, index }) => {
    const [ses] = useAtom(getToken)
    const [data, setData] = useAtom(KeyEnabledPastes)

    const [, setSettings] = useAtom(keySettings)

    // if the delete button is loading
    const [deleteLoading, setDeleteLoading] = useState(false)

    const [, setDeleteKey] = useAtom(keyDeletionVisible)
    const [, setDeleteId] = useAtom(keyDeletionId)

    const disablePasteKey = async () => {
        setDeleteKey(true)
        setDeleteId(paste.paste.pasteID)
    }

    const copyLink = () => {
        navigator.clipboard
            .writeText(`https://bloxybin.com/key/${paste?.paste?.pasteID}`)
            .then(() => toast.success("Copied link to clipboard!"))
    }

    return (
        <tr
            className={`${
                index % 2 === 0 ? "secondaryBackground" : "primaryBackground"
            } border-b border-stone-300 dark:border-stone-800`}
        >
            <th
                scope="row"
                className="lg:block md:block hidden whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
            >
                {paste.key.keyName === "" ? "Untitled Key" : paste.key.keyName}
            </th>

            <th
                scope="row"
                className="cursor-pointer whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
            >
                <Link
                    to={`/${paste.paste.pasteID}`}
                    className="hover:underline"
                >
                    {paste.paste.title === ""
                        ? "Untitled Paste"
                        : paste.paste.title}
                </Link>
            </th>

            <td className="flex flex-row gap-4 px-6 py-4">
                <button onClick={() => disablePasteKey()}>
                    {!deleteLoading ? (
                        <i className="fa-solid fa-trash"></i>
                    ) : (
                        <Spinner />
                    )}
                </button>

                <button onClick={copyLink}>
                    <i className="fa-solid fa-copy"></i>
                </button>

                <button onClick={() => setSettings(paste)}>
                    <i className="fa-solid fa-cog"></i>
                </button>
            </td>
        </tr>
    )
}
