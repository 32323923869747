import { useAtom } from "jotai"
import React, { FormEvent, useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { createPaste } from "@features/pastes/api/Pastes"
import PasteEditor from "@features/pastes/components/editor/PasteEditor"
import { getToken } from "@features/users/account/Account.atom"
import { PastesAtom } from "@features/dashboard/components/UserPastes"
import useTurnstile from "@components/hooks/UseTurnstile"

/**
 * The ability to create pastes on the homepage.
 * Includes description, title, and the editor.
 */
const PasteCreator = () => {
    const [, setDashboardPastes] = useAtom(PastesAtom)

    // if the button should have a spinner in it
    const [loading, setLoading] = useState(false)

    const [sesToken] = useAtom(getToken)
    const nav = useNavigate()

    // when the create button is pressed
    const submitPaste = async (
        ev: FormEvent<HTMLFormElement>,
        pasteContents: string,
        tags: string[],
        gameLink: string,
        universalScript: boolean,
        file?: File
    ) => {
        ev.preventDefault()
        setLoading(true)

        const data = new FormData(ev.currentTarget)

        let title = data.get("title") as string,
            desc = data.get("description") as string,
            youtubeLink = data.get("youtubeLink") as string,
            visibility = data.get("visibility") as string,
            monetized = data.get("paste-monetized") as string

        monetized = `${monetized === "on"}`

        // there must be at least 1 character in the editor
        if (pasteContents.length === 0 || title === null || desc === null) {
            setLoading(false)
            toast.error("Your paste must have at least 1 character!")
            return
        }

        try {
            const req = await createPaste(
                sesToken,
                title,
                desc,
                universalScript,
                gameLink,
                youtubeLink,
                tags,
                pasteContents,
                visibility,
                monetized,
                ref.current?.getResponse(),
                file
            )

            nav(`/${req.pasteID}`)
            toast.success("Your paste has been created!")

            // reset so this reloads
            setDashboardPastes({})
        } catch (e) {
            toast.error(`${e}`)
        }

        ref.current?.reset()
        setLoading(false)
    }

    const [turnstile, ref] = useTurnstile("paste-creator")

    return (
        <section className="island flex w-full flex-col gap-4 rounded-lg p-2 lg:w-3/4">
            {turnstile}

            <PasteEditor
                loading={loading}
                usePresetFromBackend={true}
                onSubmit={submitPaste}
            />
        </section>
    )
}
export default PasteCreator
