import { useAtom } from "jotai"
import {
    keyCreationMonetization,
    keyCreationExpiration,
    keyCreationName,
    keyCreationProcess,
    KeyEnabledPastes,
    keySelectedPaste,
} from "@features/keys/Keys.atom"
import Modal from "@components/Modal"
import Button from "@components/inputs/Button"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { enablePaste } from "@features/keys/api/ManagePasteKeys"
import { getToken } from "@features/users/account/Account.atom"
import toast from "react-hot-toast"

export default function KeyOverview() {
    const [step, setStep] = useAtom(keyCreationProcess)
    const [token] = useAtom(getToken)

    const [loading, setLoading] = useState(false)

    const [name, setName] = useAtom(keyCreationName)
    const [expiration, setExpiration] = useAtom(keyCreationExpiration)
    const [monetized, setMonetized] = useAtom(keyCreationMonetization)
    const [data, setData] = useAtom(KeyEnabledPastes)
    const [{ pasteTitle, pasteID }] = useAtom(keySelectedPaste)

    const createKey = async () => {
        setLoading(true)

        try {
            const newKey = await enablePaste(
                token,
                name,
                pasteID,
                expiration,
                monetized
            )

            // reset vars
            setName("")
            setExpiration(24)
            setMonetized(true)

            // disable modal
            setStep(0)

            // add key to the current list
            setData((prevData) => {
                const recentPage = Object.keys(data).length

                if (!prevData.hasOwnProperty(recentPage)) {
                    // if a page doesn't exist, make it.
                    return {
                        1: [newKey]
                    }
                }

                return {
                    ...prevData,
                    [recentPage]: [...prevData[recentPage], newKey],
                }
            })

            toast.success("Your key has been successfully enabled!")
        } catch (ex) {
            toast.error(`${ex}`)
        }

        setLoading(false)
    }

    return (
        <Modal
            visible={step === 3}
            setVisible={() => setStep(0)}
            title={"Overview"}
            seriesTransitioning={true}
        >
            <div className="flex flex-col px-8 pt-4 pb-8 gap-4">
                <div className="flex flex-row justify-between">
                    <p className="font-bold">Key Name</p>
                    <p>{name === "" ? "Untitled Key" : name}</p>
                </div>

                <div className="flex flex-row justify-between">
                    <p className="font-bold">Key Lifespan</p>
                    <p>{expiration} hours</p>
                </div>

                <div className="flex flex-row justify-between">
                    <p className="font-bold">Paste Selected</p>
                    <Link
                        className="hover:underline text-blue-600"
                        to={`/${pasteID}`}
                    >
                        {pasteTitle === "" ? "Untitled Paste" : pasteTitle}
                    </Link>
                </div>
            </div>

            <div className="flex flex-row justify-between">
                <Button
                    buttonStyle="secondary"
                    onClick={() => {
                        setStep(2)
                    }}
                >
                    <i className="fa-solid fa-arrow-left" /> Back
                </Button>

                <div className="relative">
                    <Button loading={loading} buttonStyle="primary" onClick={createKey}>
                        Create Key
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
