import {Banner} from "@features/status/api/Banner"
import {API} from "@util/Api"
import Payload from "@api/Payload"
import {AuthorizedGetUserResponse} from "@features/users/models/GetUserResponse"

/**
 * A status report from the backend.
 *
 * @param banner The current banner (if applicable)
 * @param placementDetails Ad placements
 * @param user The authorized get user response (if the request is authorized)
 */
export type StatusReport = {
    banner?: Banner
    placementDetails: { sourceType: string, source: string }[]
    user?: AuthorizedGetUserResponse
}

/**
 * Get the current {@link StatusReport}.
 *
 * @param sessionToken The admin session token. This provides {@link StatusReport} if added.
 */
export const getStatusReport = async (
    sessionToken?: string
): Promise<StatusReport> => {
    const headers = sessionToken
        ? {
            headers: {
                Authorization: `Bearer ${sessionToken}`,
            },
        }
        : {}

    const request = await API.get("/status", {
        ...headers,
    })

    const {payload, message}: Payload<StatusReport> = request.data

    return request.status === 200 && payload ? payload : Promise.reject(message)
}
