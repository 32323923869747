import Modal from "@components/Modal"
import { useAtom } from "jotai"
import { KeyEnabledPastes, keySettings } from "@features/keys/Keys.atom"
import React, { useEffect, useMemo, useState } from "react"
import {
    setPasteKeyExpireTime, setPasteKeyMonetized,
    setPasteKeyName,
} from "@features/keys/api/ManagePasteKeys"
import { getToken } from "@features/users/account/Account.atom"
import { toast } from "react-hot-toast"
import Button from "@components/inputs/Button"
import { useNavigate } from "react-router-dom"
import ModifiableProperty from "@components/property/ModifiableProperty"
import GetKeyEnabledPaste from "@features/keys/api/models/responses/GetKeyEnabledPaste"
import { statusReport } from "@features/status/Status.atom"
import Spinner from "@components/Spinner"

/**
 * Manage settings for a key enabled paste.
 */
const KeySettings = () => {
    const nav = useNavigate()

    const [, setData] = useAtom(KeyEnabledPastes)

    const [paste, setPaste] = useAtom(keySettings)

    const visible = useMemo(() => paste !== undefined, [paste])

    const [hours, setHours] = useState<number>()
    const [name, setName] = useState<string | undefined>(undefined)
    const [monetized, setMonetized] = useState<boolean>(false)

    useEffect(() => {
        if (paste) {
            setHours(paste.key.expireTimeMs / 3.6e6)
            setName(paste.key.keyName)
            setMonetized(paste.key.monetized)
        }
    }, [paste])

    const [session] = useAtom(getToken)
    const [status] = useAtom(statusReport)

    if (!status) return <></>

    // when the settings button is closed
    const goSettings = () => {
        nav(`/edit/${paste?.paste.pasteID}`)
        setPaste()
    }

    // find a key by it's ID and update it's instance in the atom
    const findAndUpdateKey = (
        pasteID: string,
        onFind: (oldKey: GetKeyEnabledPaste) => GetKeyEnabledPaste
    ) => {
        setData((prevData) => {
            const keys = Object.keys(prevData)

            for (let i = 0; keys.length > i; i++) {
                const page = prevData[i + 1]
                const filteredResult = page.filter(
                    (key) => key.paste.pasteID === pasteID
                )

                // this means that the key was found
                if (filteredResult.length > 0) {
                    const oldKey = filteredResult[0] // old key
                    const oldKeyIndex = page.indexOf(oldKey) // old key index

                    // newly made key, provided old key
                    page[oldKeyIndex] = onFind(oldKey)

                    return {
                        ...prevData,
                        [i + 1]: page,
                    }
                }
            }

            return prevData
        })
    }

    // update the paste key expire time
    const onSaveExpirationTime = async () => {
        if (hours && paste) {
            try {
                await setPasteKeyExpireTime(
                    session,
                    paste.paste.pasteID,
                    +hours
                )

                findAndUpdateKey(paste.paste.pasteID, (key) => ({
                    ...key,
                    key: {
                        ...key.key,
                        // hours into ms
                        expireTimeMs: hours * 60 * 60 * 1000,
                    },
                }))

                toast.success("Updated your expire time!")
            } catch (e) {
                toast.error(`${e}`)
            }
        }
    }

    // update the paste key name
    const onSaveName = async () => {
        if (name && paste) {
            try {
                await setPasteKeyName(session, paste.paste.pasteID, name)

                findAndUpdateKey(paste.paste.pasteID, (key) => ({
                    ...key,
                    key: {
                        ...key.key,
                        keyName: name,
                    },
                }))

                toast.success("Updated key's name!")
            } catch (e) {
                toast.error(`${e}`)
            }
        }
    }

    // update the paste's monetization status
    const onSaveMonetization = async (newMonetization: boolean) => {
        if (name && paste) {
            try {
                await setPasteKeyMonetized(session, paste.paste.pasteID, newMonetization)

                findAndUpdateKey(paste.paste.pasteID, (key) => ({
                    ...key,
                    key: {
                        ...key.key,
                        monetized: newMonetization
                    },
                }))

                toast.success("Updated key's monetization!")
            } catch (e) {
                toast.error(`${e}`)
            }
        }
    }

    return (
        <Modal
            visible={visible}
            setVisible={() => setPaste()}
            title={"Key Settings"}
        >
            <ModifiableProperty
                onSave={onSaveName}
                title={"Name"}
                status={name ? name : "No name"}
                value={name}
                setValue={setName}
                inputProps={{ placeholder: "Key name" }}
                editText={
                    <>
                        <i className="fa-solid fa-pencil pr-2" /> Change
                    </>
                }
                completeText={
                    <>
                        <i className="fa-solid fa-save pr-2" /> Save
                    </>
                }
            />

            <ModifiableProperty
                onSave={onSaveExpirationTime}
                title={"Expiration in Hours"}
                status={`${hours} hours`}
                value={hours ? `${hours}` : undefined}
                setValue={(e) => setHours(+e)}
                inputProps={{ type: "number", placeholder: "Time in Hours" }}
                editText={
                    <>
                        <i className="fa-solid fa-pencil pr-2" /> Change
                    </>
                }
                completeText={
                    <>
                        <i className="fa-solid fa-save pr-2" /> Save
                    </>
                }
            />

            <div className="acc-set-column rounded-lg bg-primaryBackground p-4 dark:bg-dark_primaryBackground">
                <div className="flex justify-between gap-2">
                    <div className="flex flex-col">
                        <span className="text-sm font-medium text-gray-500">
                            Monetization
                        </span>

                        <span className="max-w-xs break-words text-lg font-semibold text-black dark:text-white">
                            {monetized ? "Monetized" : "Un-monetized"}
                        </span>
                    </div>

                    <button
                        type="button"
                        className="acc-set-button mt-1 self-start"
                        onClick={() => {

                            onSaveMonetization(!monetized)
                                .then(() => setMonetized(!monetized))
                        }}
                    >
                        <i className="fa-solid fa-pencil pr-2" /> Toggle
                    </button>
                </div>
            </div>

            <div className="mt-4 flex flex-row gap-4">
                <Button onClick={goSettings} buttonStyle={"primary"}>
                    Paste Settings
                </Button>

                <Button onClick={() => setPaste()} buttonStyle={"secondary"}>
                    Close
                </Button>
            </div>
        </Modal>
    )
}

export default KeySettings
