import axios from "axios"
import {BASE_URL, TOR} from "@util/Util"
import axiosRetry from "axios-retry"

/**
 * Default API instance.
 *
 * If it's {@see TOR}, add a `Tor-Client` header.
 */
export const API = axios.create({
    baseURL: BASE_URL,
    validateStatus: () => true,
    headers: TOR ? {
        "Tor-Client": "true"
    } : {}
})

axiosRetry(API, {
    retries: 5,
    retryDelay: () => 2000,
    retryCondition: (error) => {
        return error?.response?.status === 429
    },
})

export const header = (session: string, hasBody: boolean = false) => {
    let headers: any = {
        Authorization: `Bearer ${session}`,
    }

    if (hasBody)
        headers = {
            ...headers,
            "Content-Type": "application/x-www-form-urlencoded",
        }

    return {headers}
}

export const contentHeaders = () => {
    return {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }
}

/**
 * Create a body for an API request.
 *
 * @param data Body details.
 */
export const body = (data: any): URLSearchParams => {
    const form = new FormData()
    const keys = Object.keys(data)

    keys.forEach((key) => {
        const foundData = data[key]

        if (foundData) form.append(key, foundData)
    })

    return new URLSearchParams(form as any)
}
