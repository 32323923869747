import { Paste } from "./models/Paste"
import { GetPasteResponse } from "./models/GetPasteResponse"
import { SearchResult } from "../../layout/api/responses/SearchResult"
import { SortBy } from "./SortBy"
import { BASE_URL } from "@util/Util"
import Payload from "@api/Payload"
import { API, body, contentHeaders, header } from "@util/Api"

/**
 * Get a paste by it's ID.
 *
 * @param id The paste's ID.
 * @param sessionToken The user's session token. If undefined, not included.
 */
export const getPaste = async (
    id: string,
    sessionToken?: string
): Promise<GetPasteResponse> => {
    let headers = {}

    if (sessionToken) headers = { Authorization: `Bearer ${sessionToken}` }

    const request = await API.get(`/paste?id=${id}`, {
        headers,
    })

    const body: Payload<GetPasteResponse> = request.data

    return request.status === 200 && body.payload
        ? body.payload
        : Promise.reject(body.message)
}

/**
 * Register view using a view callback token.
 *
 * @param token The view callback token.
 * @param turnstile The turnstile token.
 */
export const registerView = async (token: string, turnstile: string) => {
    await API.post(
        `${BASE_URL}/paste/view`,
        body({ token, turnstile }),
        contentHeaders()
    )
}

/**
 * Create a paste.
 *
 * @param sessionToken The session token.
 * @param turnstile The verified Turnstile token.
 * @param title The title of the paste.
 * @param description The description of the paste
 * @param universalScript Defines whether {@link gameLink} can be optional.
 * @param gameLink An optional link to the game where this paste is applicable.
 * @param youtubeLink An optional link to a YouTube video if applicable.
 * @param tags Optional tags that help further describe the paste.
 * @param content The content for the paste.
 * @param monetized If the paste is monetized.
 * @param visibility The visibility for this paste.
 * @param thumbnail An optional thumbnail for the paste.
 * @returns The newly created paste.
 */
export const createPaste = async (
    sessionToken: string,
    title: string,
    description: string,
    universalScript: boolean,
    gameLink: string,
    youtubeLink: string,
    tags: string[],
    content: string,
    visibility: string,
    monetized: string,
    turnstile?: string,
    thumbnail?: File
): Promise<Paste> => {
    let headers = {}

    if (sessionToken !== "")
        headers = { Authorization: `Bearer ${sessionToken}` }

    const formData = new FormData()

    formData.append("title", title)
    formData.append("description", description)
    formData.append("content", content)
    formData.append("monetized", monetized)
    formData.append("visibility", `${visibility}`)
    formData.append("universalScript", `${universalScript}`)
    if (gameLink !== "") formData.append("gameLink", gameLink)
    if (youtubeLink !== "") formData.append("youtubeLink", youtubeLink)
    if (tags.length > 0) formData.append("tags", JSON.stringify(tags))
    if (turnstile) formData.append("turnstile", turnstile)
    if (thumbnail) formData.append("thumbnail", thumbnail)

    const request = await API.put(`${BASE_URL}/paste`, formData, {
        headers,
    })

    const { payload, message }: Payload<Paste> = request.data

    return request.status === 200 && payload ? payload : Promise.reject(message)
}

/**
 * Update a paste's thumbnail.
 *
 * @param session the session token.
 * @param pasteID The paste to update's ID
 * @param thumbnail the thumbnail itself.
 */
export const updatePasteThumbnail = async (
    session: string,
    pasteID: string,
    thumbnail: File
): Promise<void> => {
    const req = await API.post(`${BASE_URL}/paste/thumbnail`, thumbnail, {
        headers: {
            Authorization: `Bearer ${session}`,
            paste: pasteID,
        },
    })
    const { message }: Payload<undefined> = req.data

    if (req.status !== 200) return Promise.reject(message)
}

/**
 * Search pastes by keywords and/or sort_by. Both can't be undefined.
 *
 * @param searchPhrase The search phrase.
 * @param sortBy How to sort the posts.
 * @param page The page to request.
 * @returns The list of pastes with search parameters.
 */
export const searchPastes = async (
    page: number,
    searchPhrase?: string,
    sortBy?: SortBy
): Promise<SearchResult> => {
    let requestBody: any = {
        page,
        sort_by: sortBy ? SortBy[sortBy] : SortBy[SortBy.RECENT],
    }

    if (searchPhrase)
        requestBody = {
            ...requestBody,
            keywords: searchPhrase,
        }

    const request = await API.post(
        `/paste/search`,
        requestBody,
        contentHeaders()
    )

    const { payload, message }: Payload<SearchResult> = request.data

    return request.status === 200 && payload ? payload : Promise.reject(message)
}

/**
 * Search cached pastes.
 *
 * @param type The type of cached pastes.
 */
export const searchCachedPastes = async (type: string): Promise<Paste[]> => {
    const request = await API.get(`/paste/search?type=${type}`)
    const body: Payload<Paste[]> = request.data

    return request.status === 200 && body.payload
        ? body.payload
        : Promise.reject(body.message)
}

/**
 * Get popular pastes.
 */
export const popularPastes = async (): Promise<Paste[]> => {
    const request = await API.get(`/paste/popular?period=WEEKLY`)
    const { payload, message }: Payload<Paste[]> = request.data

    return request.status === 200 && payload ? payload : Promise.reject(message)
}

/**
 * Update an attribute of a paste.
 *
 * @param sesToken The session token.
 * @param pasteID The paste ID to edit.
 * @param attribute The attribute of the paste, like: title, content, description
 * @param value The new value for the attribute.
 * @returns A boolean if successful. If not, a promise rejection is thrown for compatibility with toasts.
 */
export const updatePasteAttribute = async (
    sesToken: string,
    pasteID: string,
    attribute: string,
    value?: string
): Promise<void> => {
    const request = await API.post(
        `${BASE_URL}/paste`,
        body({
            key: attribute,
            value: value ?? "",
            pasteID,
        }),
        header(sesToken, true)
    )

    const { message }: Payload<undefined> = request.data

    if (request.status !== 200) return Promise.reject(message)
}

/**
 * Delete a paste by it's paste ID.
 *
 * @param sesToken The current session token.
 * @param pasteID The paste to delete.
 * @returns True if successful, throws a promise.reject if it's bad request :(
 */
export const deletePaste = async (
    sesToken: string,
    pasteID: string
): Promise<void> => {
    const request = await API.delete(
        `${BASE_URL}/paste?pasteID=${pasteID}`,
        header(sesToken)
    )

    if (request.status !== 200) {
        const { message }: Payload<undefined> = request.data

        return Promise.reject(message)
    }
}
