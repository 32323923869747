import { toast } from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import { Paste as PasteAPI } from "../api/models/Paste"
import { deletePaste } from "@features/pastes/api/Pastes"
import { timeAgo } from "@util/Util"

/**
 * A paste.
 */
const Paste: React.FC<{ paste: PasteAPI; size?: string }> = ({
    paste,
    size,
}) => {
    let title = paste.title.substring(0, 25)

    return (
        <div
            className={`${
                size ? size : "w-full xl:w-1/2 2xl:w-1/3"
            } paste secondaryBackground`}
        >
            <a target={"_self"} href={`/${paste.pasteID}`}>
                {title === "" ? "Untitled Paste" : title}
            </a>
            <p>{timeAgo(new Date(paste.date))}</p>
        </div>
    )
}

export default Paste

/**
 * A toast to delete a paste.
 * @param sessionToken The current session token.
 * @param pasteID The paste ID to delete
 * @returns the function to delete
 */
export const useDeletePasteToast = (sessionToken: string, pasteID: string) => {
    const nav = useNavigate()

    return () =>
        toast(
            (t) => (
                <div className="flex flex-row gap-2">
                    <span>Are you sure you want to delete this paste?</span>

                    <button
                        className="text-blue-600 hover:text-blue-800"
                        onClick={() => {
                            // if they click yes, add a new toast with a promise
                            toast.dismiss(t.id)

                            toast.promise(deletePaste(sessionToken, pasteID), {
                                loading: "Deleting paste...",
                                // on success
                                success: () => {
                                    nav("/")
                                    return "Your paste has been successfully deleted!"
                                },
                                // return promise error on error
                                error: (err) => err.toString(),
                            })
                        }}
                    >
                        Yes
                    </button>
                </div>
            ),
            {
                icon: <i className="fa fa-warning" />,
            }
        )
}
