import { GetPasteResponse } from "@features/pastes/api/models/GetPasteResponse"
import React from "react"
import { BASE_URL } from "@util/Util"

/**
 * Views either the paste's thumbnail or the YouTube embed, if enabled.
 */
const PasteThumbnailViewer: React.FC<{ paste: GetPasteResponse }> = ({
    paste,
}) => {
    if (paste.featureYoutubeEmbed && paste.paste.youtubeLink) {
        // get the ID of the paste's youtube video for the embed
        const youtubeLinkLength = paste.paste.youtubeLink.length
        const youtubeId = paste.paste.youtubeLink.substring(
            youtubeLinkLength - 11,
            youtubeLinkLength
        )

        return (
            <div className="flex flex-col gap-2 relative justify-center items-center w-1/2">
                {/* YouTube Video Embed*/}
                <iframe
                    title="YouTube Video Embed"
                    style={{
                        borderRadius: "10px",
                        overflow: "hidden",
                        zIndex: "1",
                    }}
                    height="192"
                    src={`https://www.youtube-nocookie.com/embed/${youtubeId}`}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                ></iframe>
            </div>
        )
    }

    if (paste.paste.hasThumbnail) {
        return (
            <div className="flex flex-col gap-2 relative justify-center items-center lg:w-1/2">
                <img
                    src={
                        paste.paste.thumbnailLink
                            ? `${paste.paste.thumbnailLink}`
                            : `${BASE_URL}/paste/thumbnail?paste=${paste.paste.pasteID}`
                    }
                    alt="Thumbnail"
                    className="mx-auto rounded-3xl aspect-video lg:h-48 h-36 thumbnailShadow"
                    loading="lazy"
                />
            </div>
        )
    }

    return <></>
}

export default PasteThumbnailViewer
