import { useAtom } from "jotai"
import { referralStatistics } from "@features/users/referrals/Referrals.atom"
import Card from "@components/Card"

/**
 * Revenue gained from referrals.
 */
const ReferralSignups = () => {
    const [stats] = useAtom(referralStatistics)

    return (
        <Card
            title={"Referral Signups"}
        >
            <span className="monospace">{stats?.lifetimeUsers}</span> sign-ups
        </Card>
    )
}
export default ReferralSignups
