import { useAtom } from "jotai"
import {
    robloxUniverseId, robuxSupply,
    withdrawalAmount,
    withdrawalProcess,
    withdrawalType,
} from "@features/users/withdrawals/Withdrawals.atom"
import Modal from "@components/Modal"
import { getToken } from "@features/users/account/Account.atom"
import Input from "@components/inputs/Input"
import React, { useState } from "react"
import Button from "@components/inputs/Button"
import { WithdrawalType } from "@features/users/withdrawals/api/models/WithdrawalType"
import { validateGamePass } from "@features/users/withdrawals/api/Withdrawals"
import toast from "react-hot-toast"

/**
 * Confirm details for withdrawal.
 */
const RobuxWithdrawalConfirmDetails = () => {
    const [step, setStep] = useAtom(withdrawalProcess)
    const [type] = useAtom(withdrawalType)

    const [loading, setLoading] = useState<boolean>(false)

    const [sesToken] = useAtom(getToken)

    const [, setUniverseId] = useAtom(robloxUniverseId)

    const [gamePassUrl, setGamePassUrl] = useState<string>("")
    const [validated, setValidated] = useState<boolean>(false)

    // the amount the user's withdrawing
    const [amount] = useAtom(withdrawalAmount)

    const validateUrl = async () => {
        setLoading(true)

        try {
            const universeId = await validateGamePass(sesToken, gamePassUrl, amount)

            setValidated(true)
            toast.success("Validated!")

            setUniverseId(universeId)
        } catch (e) {
            toast.error(`${e}`)
        }

        setLoading(false)
    }

    return (
        <Modal
            visible={step === 3 && type === WithdrawalType.ROBUX}
            setVisible={() => setStep(0)}
            title={"Robux Payout"}
            seriesTransitioning={true}
        >
            <div className="flex flex-col my-4">
                <ol className="list-decimal ml-8">
                    <li>
                        On Roblox navigate to the{" "}
                        <a
                            href="https://create.roblox.com/dashboard/creations"
                            className="text-blue-700 underline"
                            target="_blank"
                            rel="noreferrer"
                        >
                            create page
                        </a>
                        .
                    </li>

                    <li>Select any game / experience.</li>

                    <li>Under "Monetization" click "Passes."</li>

                    <li>Create or select an existing game pass and click "Sales."</li>

                    <li>
                        Enable "Item for Sale"
                    </li>

                    <li>
                        Set the price to{" "}
                        <span className="font-bold">
                            {amount}
                        </span>{" "}
                        & save changes.
                    </li>

                    <li>
                        Copy the URL from your browser, and paste in the area
                        below.
                    </li>
                </ol>
            </div>

            <div className="flex flex-row gap-4 justify-center mb-8">
                <Input
                    value={gamePassUrl}
                    placeholder="Copied URL"
                    onChange={(e) => {
                        setGamePassUrl(e.currentTarget.value)
                    }}
                />

                <Button
                    buttonStyle="success"
                    loading={loading}
                    disabled={gamePassUrl === ""}
                    onClick={validateUrl}
                >
                    Validate
                </Button>
            </div>

            <div className="flex flex-row justify-between">
                <Button buttonStyle="secondary" onClick={() => setStep(2)}>
                    <i className="fa-solid fa-arrow-left" /> Back
                </Button>

                <Button
                    buttonStyle="primary"
                    onClick={() => setStep(4)}
                    loading={loading}
                    disabled={!validated}
                >
                    Confirmation <i className="fa-solid fa-arrow-right" />
                </Button>
            </div>
        </Modal>
    )
}

export default RobuxWithdrawalConfirmDetails
