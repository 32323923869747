import React, {useEffect, useState} from "react"
import useRange from "@util/useRange"
import Card, {CardProps} from "@components/Card"

/**
 * Properties for a time ranged statistic card.
 *
 * @remark
 * This inherits the Card props, see {@link CardProps} for more details.
 *
 * @param units The optional units for {@param initialAmount}. For example, `views`.
 * @param initialAmount The initial statistic. This should be in line with {@param defaultRange}.
 * @param onUpdateRange The statistic for `newRange`.
 * @param ranges Optional naming for the ranges. For example, `{ daily: "Daily", lifetime: "Lifetime"  }`
 * @param defaultRange The initial range. This should be an included range in {@param ranges}, and correlate with {@param initialAmount}.
 * @param fixed The amount of digits to include on the statistic.
 */
type TimeRangeCardProps = {
    units?: string
    initialAmount: number
    onUpdateRange: (newRange: string) => Promise<number>
    ranges?: Record<string, string>
    defaultRange?: string
    fixed?: number
} & CardProps

/**
 * A loading skeleton for time ranged cards.
 *
 * @remark
 * Although loading is a property on {@link TimeRangeCardProps}, many times it utilized since an undefined variable
 * will prevent it from being loaded. This is used instead, as it only requires known variables.
 *
 * @param title The title of the card.
 * @param viewMoreText The view more text, if applicable.
 * @param className Additional class names.
 */
export const TimeRangeCardSkeleton: React.FC<{
    title: string
    viewMoreText?: string
    className?: string
}> = ({title, viewMoreText, className}) => {
    return (
        <div
            className={`w-full flex flex-row items-center justify-between primaryBackground p-4 gap-4 rounded-lg ${className}`}
        >
            <div>
                <div className="flex flex-row gap-2">
                    <h1 className="text-dark_greyText text-left text-md font-semibold poppins">
                        {title}
                    </h1>

                    {viewMoreText && (
                        <button className="text-gray-600 text-sm underline">
                            {viewMoreText}
                        </button>
                    )}
                </div>

                <p className="h-[32px] flex items-center mt-2 text-white animate-pulse">
                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5 w-3/4"></div>
                </p>
            </div>

            <div className="animate-pulse h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5 w-1/5"></div>
        </div>
    )
}

/**
 * A card that includes a time range. Often used for period statistics.
 *
 * @see TimeRangeCardProps
 * @see Card
 */
export default function TimeRangeCard(props: TimeRangeCardProps) {
    const [range, rangeElement] = useRange(
        props.title,
        props.defaultRange,
        props.ranges
    )
    const [amount, setAmount] = useState({
        [props.defaultRange ?? "daily"]: props.initialAmount,
    })

    const {onUpdateRange} = props // to prevent props in the useEffects dependencies
    useEffect(() => {
        const loadAmount = async () => {
            if (!amount.hasOwnProperty(range)) {
                const rangeAmount = await onUpdateRange(range)

                setAmount((prev) => ({
                    ...prev,
                    [range]: rangeAmount,
                }))
            }
        }

        loadAmount().finally(() => console.log(`Successfully loaded ${range}.`))
    }, [amount, onUpdateRange, range])

    return (
        <Card
            {...props}
            action={rangeElement}
            loading={!Object.hasOwn(amount, range)}
        >
            {Object.hasOwn(amount, range) ? (
                <>
                        <span className="monospace">
                            {!props.units && "$"}

                            {/* Show a props.fixed amount of decimals*/}
                            {props.fixed
                                ? amount[range].toFixed(props.fixed)
                                : amount[range]}
                        </span>{" "}
                    {props.units ?? ""}
                </>
            ) : (
                <></>
            )}
        </Card>
    )
}
