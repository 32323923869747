import Editor from "@components/Editor"
import Button from "@components/inputs/Button"
import TextArea from "@components/inputs/TextArea"
import Input from "@components/inputs/Input"
import React, { useState } from "react"
import Visibility from "@features/pastes/components/editor/Visibility"
import { Visibility as Vis } from "@features/pastes/api/Visibility"
import { useAtom } from "jotai"
import useTags from "@components/inputs/Tags"
import PasteMonetization from "@features/pastes/components/editor/PasteMonetization"
import { isLoggedIn } from "@features/users/account/Account.atom"
import { GetPasteResponse } from "@features/pastes/api/models/GetPasteResponse"
import PasteGameLink from "@features/pastes/components/editor/PasteGameLink"
import { Asterisk } from "@features/pastes/components/editor/Asterisk"

type PasteEditorProps = {
    initPasteResponse?: GetPasteResponse
    usePresetFromBackend?: boolean
    loading: boolean
    viewButton?: boolean
    onView?: () => void
    onSubmit: (
        ev: React.FormEvent<HTMLFormElement>,
        pasteContents: string,
        tags: string[],
        gameLink: string,
        universalScript: boolean,
        file?: File
    ) => void
}

/**
 * An editor for a paste.
 * This is used for both the creator on the homepage and the edit page.
 */
const PasteEditor = ({
    loading,
    initPasteResponse,
    onSubmit,
    viewButton,
    onView
}: PasteEditorProps) => {
    const { paste: initPaste } = initPasteResponse ?? {}

    const [gameLink, setGameLink] = useState<string>(initPaste?.gameLink ?? "")
    const [universalScript, setUniversalScript] = useState(
        initPaste?.universalScript ?? false
    )

    // determines if the user can use diff features (ex: ads)
    const [loggedIn] = useAtom(isLoggedIn)

    // the thumbnail of the paste.
    const [thumbnail, setThumbnail] = useState<File>()

    // contents of paste
    const [pasteContents, setPasteContents] = useState<string>(
        initPasteResponse?.content ?? ""
    )

    // paste tags
    const [tags, tagsElement] = useTags(initPaste?.tags ?? [])

    // description length, shows a char count in the top right of the text area
    const [descLen, setDescLen] = useState<number>(
        initPaste?.description?.length ?? 0
    )

    return (
        <>
            <Editor
                value={pasteContents}
                onChange={(newStr) => setPasteContents(newStr)}
            />

            <form
                className="flex flex-col justify-between gap-4 px-2 md:flex-row"
                onSubmit={(e) =>
                    onSubmit(
                        e,
                        pasteContents,
                        tags,
                        gameLink,
                        universalScript,
                        thumbnail
                    )
                }
            >
                <div className="flex w-full grid-cols-3 flex-col gap-x-4 gap-y-4 lg:grid lg:gap-y-0">
                    {/* Create button */}
                    {viewButton ? (
                        <div className="w-full gap-2 items-center flex flex-row">
                            <Button
                                type="submit"
                                className="w-full"
                                loading={loading}
                            >
                                Publish
                            </Button>
                            <Button
                                className="w-full"
                                loading={loading}
                                onClick={onView}
                                buttonStyle="secondary"
                            >
                                View
                            </Button>
                        </div>
                    ) : (
                        <div className="mt-6 w-full">
                            <Button
                                type="submit"
                                className="w-full"
                                loading={loading}
                            >
                                Publish
                            </Button>
                        </div>
                    )}

                    {/* Paste Description */}
                    <div className="row-span-2 h-full">
                        <div className="flex flex-row items-center justify-between">
                            Paste Description
                            <span className="monospace text-sm text-greyText dark:text-dark_greyText">
                                {descLen}/256
                            </span>
                        </div>

                        <TextArea
                            name="description"
                            placeholder="Paste description here"
                            maxLength={256}
                            className="scrollbarHidden h-[78.5%]"
                            defaultValue={initPaste?.description ?? ""}
                            onChange={(e) =>
                                setDescLen(e.currentTarget.value.length)
                            }
                        />
                    </div>

                    <label className="input-label w-full">
                        Universal Script
                        <div className="secondaryBackground flex flex-row items-center justify-between rounded-lg border p-2 px-3 dark:border-dark_border">
                            <span className="mr-3 text-sm text-gray-900 dark:text-gray-300">
                                Universal Script
                            </span>

                            <label
                                className={`relative inline-flex cursor-pointer items-center`}
                            >
                                <input
                                    type="checkbox"
                                    name="paste-monetized"
                                    className="peer sr-only"
                                    checked={universalScript}
                                    onChange={(ev) =>
                                        setUniversalScript(
                                            ev.currentTarget.checked
                                        )
                                    }
                                />

                                <div
                                    className="peer h-6 w-11
                    rounded-full bg-gray-200 after:absolute after:left-[2px]
                    after:top-0.5 after:h-5
                     after:w-5 after:rounded-full
                     after:border after:border-gray-300 after:bg-white
                     after:transition-all after:content-[''] peer-checked:bg-success
                     peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-green-300
                     dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-green-800"
                                ></div>
                            </label>
                        </div>
                    </label>

                    {/* Title of Paste */}
                    <label className="input-label w-full">
                        Paste Title
                        <Input
                            name="title"
                            placeholder="Paste title here"
                            maxLength={64}
                            defaultValue={initPaste?.title ?? ""}
                        />
                    </label>

                    {/* Game Link */}
                    <PasteGameLink
                        gameLink={gameLink}
                        setGameLink={setGameLink}
                        universalScript={universalScript}
                    />

                    {/* Visibility of Paste */}
                    <label className="input-label w-full">
                        Visibility <Asterisk />
                        <Visibility
                            defaultValue={initPaste?.visibility ?? Vis.PUBLIC}
                        />
                    </label>

                    {/* Paste Tags */}
                    <label className="input-label w-full md:ml-0">
                        Tags
                        <br />
                        {tagsElement}
                    </label>

                    {/* Youtube Video URL */}
                    <label className="input-label w-full md:ml-0">
                        YouTube Link
                        <br />
                        <Input
                            name="youtubeLink"
                            placeholder="YouTube Video URL"
                            maxLength={128}
                            defaultValue={initPaste?.youtubeLink ?? ""}
                        />
                    </label>
                </div>

                <div className="flex w-full flex-col md:w-1/3">
                    {/* Ad Configurations */}

                    <label className="input-label w-full">
                        Monetization
                        <PasteMonetization
                            hideTitle={true}
                            extendSize
                            defaultChecked={
                                initPasteResponse?.paste?.monetized ?? true
                            }
                        />
                    </label>

                    {/* Paste Thumbnail */}
                    {loggedIn && (
                        <label className="input-label mt-0.5 w-full">
                            Thumbnail
                            <br />
                            <input
                                className="secondaryBackground w-full rounded-lg p-2"
                                style={{ fontSize: "0.75rem" }}
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={(e) =>
                                    setThumbnail((e.target.files ?? [])[0])
                                }
                            />
                        </label>
                    )}
                </div>
            </form>
        </>
    )
}

export default PasteEditor
