import {useAtom} from "jotai"
import {KeyAdGuideVisible} from "@features/keys/Keys.atom"
import Modal from "@components/Modal"
import Button from "@components/inputs/Button"
import React from "react"
import Card from "@components/Card"

/**
 * A modal instructing users on how ads work on keys.
 */
const KeyAdGuide = () => {
    const [visible, setVisible] = useAtom(KeyAdGuideVisible)

    return (
        <>
            <Modal
                visible={visible}
                setVisible={setVisible}
                title={"Key Ad Guide"}
            >
                <div className="space-y-4">
                    <h2 className="poppins">Where do I see my keys revenue?</h2>
                    <p>
                        Creating keys count as regular views! Your revenue will
                        appear in the regular section.
                    </p>

                    <Button
                        buttonStyle="secondary"
                        onClick={() => setVisible(false)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
            <Card
                title={"Ad Guide"}
            >
                <button
                    className="underline cursor-pointer"
                    onClick={() => setVisible(true)}
                >
                    Learn about ads
                </button>
            </Card>
        </>
    )
}

export default KeyAdGuide
