import { atom } from "jotai"
import GetPasteAnalytics from "@features/keys/api/models/responses/GetPasteAnalytics"
import GetKeyEnabledPaste from "@features/keys/api/models/responses/GetKeyEnabledPaste"

/**
 * If the ad guide modal is visible.
 */
export const KeyAdGuideVisible = atom(false)

/**
 * Pastes which have the key system enabled.
 */
export const KeyEnabledPastes = atom<Record<number, GetKeyEnabledPaste[]>>({})

/**
 * Analytics for paste keys.
 */
export const PasteKeyAnalytics = atom<GetPasteAnalytics | undefined>(undefined)

/**
 * Key settings paste.
 */
export const keySettings = atom<GetKeyEnabledPaste | undefined>(undefined)

/**
 * What process the Key creation is on.
 */
export const keyCreationProcess = atom<number>(0)

/**
 * The name for the created key.
 */
export const keyCreationName = atom<string>("")

/**
 * The expiration (in hours) for the newly created key.
 */
export const keyCreationExpiration = atom<number>(24)

/**
 * The key creation ad config
 */
export const keyCreationMonetization = atom<boolean>(true)

/**
 * The selected paste from {@link keyCreationName} step 2.
 */
export const keySelectedPaste = atom<{ pasteTitle: string; pasteID: string }>({
    pasteTitle: "",
    pasteID: "",
})

/**
 * If the key deletion confirmation page is visible.
 */
export const keyDeletionVisible = atom(false)

/**
 * The ID to confirm deletion for.
 */
export const keyDeletionId = atom<string>("")
