import React from "react"
import { Paste as PasteApi } from "@features/pastes/api/models/Paste"
import { Link, useNavigate } from "react-router-dom"
import { useAtom } from "jotai"
import { themeAtom } from "@features/theme/Theme.atom"
import { BASE_URL } from "@util/Util"

const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
]

/**
 * Get the image index from alphabet.
 *
 * @param letter The letter (or number), last character of paste ID
 */
const getIndex = (letter: string) => {
    if (!isNaN(+letter)) return +letter + 1

    return alphabet.indexOf(letter.toUpperCase()) + 1
}

/**
 * A popular paste.
 */
export const ThumbnailPaste: React.FC<{ paste: PasteApi }> = ({ paste }) => {
    const nav = useNavigate()

    const [theme] = useAtom(themeAtom)

    let lastLetter =
        paste.pasteID.substring(
            paste.pasteID.length - 1,
            paste.pasteID.length
        ) ?? "a"

    return (
        <div className={"thumb"} onClick={() => nav(`/${paste.pasteID}`)}>
            <img
                src={
                    !paste.thumbnailLink
                        ? `/img/paste/${getIndex(lastLetter)}.jpg`
                        : paste.thumbnailLink
                }
                className={
                    theme === "dark" ? "darkDropShadow" : "lightDropShadow"
                }
                alt="Thumbnail"
            />

            <a target={"_self"} href={`/${paste.pasteID}`} className="text">
                {paste.title ? paste.title.substring(0, 25) : "Untitled Paste"}
            </a>
        </div>
    )
}
