import React, { useState } from "react"
import { RevenueReport } from "@features/users/account/api/models/responses/GetStatistics"
import { useAtom } from "jotai"
import { toast } from "react-hot-toast"
import { getToken } from "@features/users/account/Account.atom"
import { getRevenueRange } from "@features/users/account/api/Statistics"
import PerDayRevenue, { perDayRevenueVisible } from "@features/dashboard/components/statistics/revenue/PerDayRevenue"
import TimeRangeCard, { TimeRangeCardSkeleton } from "@components/TimeRangeCard"
import { MoneroPayoutModal } from "@features/dashboard/components/payout/MoneroPayoutModal"

/**
 * Per day revenue reports
 */
const PasteRevenue: React.FC<{ defaultReport?: RevenueReport }> = ({
    defaultReport,
}) => {
    const [, setVisible] = useAtom(perDayRevenueVisible)
    const [range, setRange] = useState<string>("DAILY")

    const [ses] = useAtom(getToken)

    const loadRange = async (range: string) => {
        try {
            setRange(range)

            const report = await getRevenueRange(ses, range)

            return report.amount
        } catch (e) {
            toast.error(`${e}`)

            return 0
        }
    }

    if (!defaultReport) {
        return <TimeRangeCardSkeleton title={"Revenue"} viewMoreText={"View more"} className="lg:w-1/3" />
    }

    return (
        <>
            <TimeRangeCard
                className="lg:w-1/3"
                title={"Revenue"}
                defaultRange={"MONTHLY"}
                ranges={{
                    DAILY: "Yesterday",
                    WEEKLY: "Weekly",
                    MONTHLY: "Monthly",
                    LIFETIME: "Lifetime",
                }}
                initialAmount={defaultReport.amount}
                fixed={2}
                viewMoreText={"View more"}
                viewMore={() => setVisible(true)}
                onUpdateRange={(range) => loadRange(range)}
            />

            {/* Modal for paying out */}
            <MoneroPayoutModal />

            <PerDayRevenue range={range} />
        </>
    )
}

export default PasteRevenue
